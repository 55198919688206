@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

.logo {
    height: 150px;
}

.navbar {
    font-size: 25px;
    font-family: 'Exo', sans-serif;
}

.navbar a {
    padding: 0px 20px 0px 20px;
    color: rgb(192 194 206);
    text-decoration: none;
}

.navbar a:hover {
    color: white
}

