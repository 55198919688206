@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

body {
  background-color: #282c34;
  color: #ffffff;
  font-family: 'Exo', sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.test {
  height: 150px;
}
